<!-- 新闻详情 -->
<template>
	<div class="xinWenXiangQing" id="xinWenXiangQing">
		<!-- 主体 -->
		<div class="page-content">
			<div class="main-content" v-if="contentInfo">
				<div class="path">
					<div class="txt-1" @click="goBack">{{ fromName == 'newsCenter' ? '新闻中心':'首页' }}</div>
					<div class="symbol"> - </div>
					<div class="txt-2" style="color: rgba(28, 115, 222, 1);">新闻详情</div>
				</div>
				<div class="new-box">
					<div class="title">{{ contentInfo.contentTitle }}</div>
					<div class="date-source">
						<div class="date">发布时间 : {{ getDate(contentInfo.createTime) }}</div>
						<div class="source">来源：{{ contentInfo.contentSource }}</div>
					</div>
					<div
						v-if="isMobile"
						class="rich-mobile"
						style="font-size: 16px;max-width: 1200px;margin: 39px auto;"
						v-html="contentInfo.contentDetails && formatRichText(contentInfo.contentDetails)"
					></div>
					<div v-else class="rich-pc" style="font-size: 16px;max-width: 1200px;margin: 39px auto;" v-html="contentInfo.contentDetails"></div>
				</div>
				<div class="related-reading" v-if="contentRandom.length">
					<div class="heading">相关阅读</div>
					<div class="list">
						<div class="item" v-for="(item, index) in contentRandom" :key="index" @click="$router.replace({ name: 'newsDetail', params: { id: item.id } })">
							<div class="title">{{ item.contentTitle }}</div>
							<div class="description">{{ item.contentDescription }}</div>
							<div class="date-source">{{ getDate(item.createTime) }}来源：{{ item.contentSource }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ContentInfo, ContentRandom } from '@/apis/wenZhangLanMu.js';
export default {
	props: ['id'],
	data() {
		return {
			fromName: '',
			contentInfo: null,
			contentRandom: [],
			
		};
	},
	watch: {
		id: {
			handler(id, oldid) {
				console.log('id: ', id);
				console.log('oldid: ', oldid);
				this.getContentInfo();
				if (!oldid && !this.fromName) {
					this.fromName = 'newsCenter'
				}
			},
			immediate: true
		},
		$route: {
			handler(val, oldval) {
				console.log(val, 'val'); //新路由信息
				console.log(oldval, 'oldval'); //老路由信息
			},
			deep: true,
			immediate: true
		}
	},
	beforeRouteEnter(to, from, next) {
		console.log("to111: ",to);
		console.log("from111: ",from);
		next(vm => {
			if (from.name != null && from.name != 'newsDetail') {
				vm.fromName = from.name
			}
		})
	},
	methods: {
		goBack() {
			this.$router.go(-1)
		},
		getContentInfo() {
			ContentInfo(this.id).then(res => {
				this.contentInfo = res.data;
				this.getContentRandom();
			});
		},
		getContentRandom() {
			ContentRandom(this.id, 2).then(res => {
				this.contentRandom = res.data;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
@import './xinWenXiangQing.scss';
@import './xinWenXiangQingMedia.scss';
</style>
